import React, {useEffect, useState} from "react";
import {
  ActionFunctionArgs,
  defer,
  json,
  Link,
  redirect,
  useActionData,
  useLoaderData,
  useNavigate
} from "react-router-dom";

import {SliceState, useStore} from "../store/use-store";
import BlurBox from "../layout/BlurBox";
import AuthFormsContent from "../components/AuthFormsContent";
import {contentEN, contentFR} from "../data/layer-connexion";
import classes from "./Login.module.css";
import {User} from "../models/user";
import Title from "../components/Title";
import Button from "../components/Button";

const Login: React.FC = () => {
  const [error, setError] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState<{status: number; user: User;} | null>(null);
  const [languageStore] = useStore("language") as [SliceState, any];
  const actionData = useActionData() as { error?: boolean; message: string; formSend?: boolean };
  const { userInfos } = useLoaderData() as userInfos;
  const navigate = useNavigate();

  useEffect(() => {
    if (actionData?.message) {
      setError(true);
    }
  }, [actionData]);

  useEffect(() => {
    if (userInfos.status === 200) {
      setIsLogged(true);
      setUser(userInfos);
    }
  }, [userInfos]);

  function errorHandler() {
    setError(false);
  }

  async function logoutHandler() {
    try {
      const response = await fetch(process.env.REACT_APP_BACK_URL + "/user/logout", {
        credentials: "include",
      });
      console.log(response)
      navigate(0); //Refresh the page
    } catch (error) {
      throw json({ message: "Erreur d'appel (logout)" }, { status: 500 });
    }
  }

  const labelsFR = {
    title: "Bienvenue",
    subtitle: "Veuillez renseigner votre adresse mail* et votre mot de passe.",
    asterisk: "*Attention, l'adresse email à indiquer est celle que vous avez communiquée à l'Euro Disney Club.",
    placeholderEmail: "Adresse e-mail",
    placeholderPassword: "Mot de passe",
    button: "Connexion",
    leave: { url: "request-password", text: "Mot de passe oublié ?" },
    legal: contentFR,
    isLogged: `Vous êtes connecté en tant que ${user?.user.firstname} ${user?.user.lastname}`,
    goHome: "Retourner sur l'accueil",
    logout: "Se déconnecter",
  };

  const labelsEN = {
    title: "Welcome",
    subtitle: "Please enter your email adress* and your password.",
    placeholderEmail: "Email adress",
    asterisk: "*Please note that you need to register with the email address you gave to the Euro Disney Club.",
    placeholderPassword: "Password",
    button: "Login",
    leave: { url: "request-password", text: "Forgotten password?" },
    legal: contentEN,
    isLogged: `You are logged as ${user?.user.firstname} ${user?.user.lastname}`,
    goHome: "Go back to home page",
    logout: "Logout",
  };

  let labels = labelsFR;
  if (languageStore.lang === "EN" || languageStore.lang === "ENG") labels = labelsEN;
  return (
    <>
      <BlurBox>
        {!isLogged && (
          <AuthFormsContent
            type="login"
            labels={labels}
            error={{ status: error, message: actionData?.message }}
            errorHandler={errorHandler}
          />
        )}
        {isLogged && user && (
          <div className={classes.isLogged}>
            <Title title={labels.title} />
            <p>{labels.isLogged}</p>
            <div className={classes.home_btn}>
              <Link to="/">{labels.goHome}</Link>
            </div>
            <div className={classes.logout}>
              <Button onClick={logoutHandler}>{labels.logout}</Button>
            </div>
          </div>
        )}
      </BlurBox>
      <div className={classes.legalwrapper} dangerouslySetInnerHTML={{ __html: labels.legal }} />
    </>
  );
};

export default Login;

export async function action({ request }: ActionFunctionArgs) {
  const data = await request.formData();

  const formData = {
    email: data.get("email"),
    password: data.get("password")!,
  };

  let url = process.env.REACT_APP_BACK_URL + "/user/login";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(formData),
  });

  if (response.status === 200) {
    console.log(response);
    return redirect("/");
  }

  if (!response.ok) {
    console.log(response);
    return response;
    // TODO: Deal with error
  }
}

async function loadUser() {
  try {
    const response = await fetch(process.env.REACT_APP_BACK_URL + "/user/checkauth", {
      credentials: "include",
    });
    return await response.json();
  } catch (error) {
    throw json({ message: "Erreur d'appel (loadUser)" }, { status: 500 });
  }
}

export async function loader() {
  return defer({
    userInfos: await loadUser(),
  });
}

type userInfos = {
  userInfos: {
    status: number;
    user: User;
  };
};
